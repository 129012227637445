import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image";

// import Trip from '../components/trip';

import Layout from "../components/layout"
import SEO from "../components/seo"

// import HTML from '../components/html';

// import map1 from '../images/map1.jpg';
// import map2 from '../images/map2.jpg';
// import MoreLink from "../components/moreLink";

const PricesPage = ({ intl }) => {
	const {gallery} = useStaticQuery(
		graphql`
		  query {
			gallery: allFile (
			  sort: { fields: name, order: DESC }
			  filter: { relativeDirectory: { eq: "gallery" } }
			) {
			  edges {
				node {
				  id
				  name
				  childImageSharp {
					fluid(maxWidth: 400) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				  big: childImageSharp {
					fluid(maxWidth: 1200) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				}
			  }
			}
		
			
		  }
		`
	);
	return (
		// <Layout pageName="prices" siteTitle="prices.page_title" intro="prices.intro">
		// 	<SEO
		// 		lang={intl.locale}
		// 		title={intl.formatMessage({ id: "title" })}
		// 		description={intl.formatMessage({ id: "description" })}
		// 		keywords={[`gatsby`, `application`, `react`]}
		// 	/>
		
		// 	<section className="wrapper container wrap narrow">
		// 		<div className="container trips big">
		// 			<Trip
		// 				className="overlap overlap-mini"
		// 				title={intl.formatMessage({ id: "trip1.name" })}
		// 				duration={intl.formatMessage({ id: "trip1.duration" })}
						
		// 				link="/page-2"
		// 				body={<HTML>
		// 					<p>{intl.formatMessage({ id: "trip1.desc_p1" })}</p>
							
		// 					<span className="price">{intl.formatMessage({ id: "trip1.price" })}</span>
							
		// 					<p className="center">
		// 						<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
		// 					</p>
		// 				</HTML>}
		// 			/>
		// 			<Trip
		// 				className="overlap overlap-mini"
		// 				title={intl.formatMessage({ id: "trip2.name" })}
		// 				duration={intl.formatMessage({ id: "trip2.duration" })}
						
		// 				link="/page-2"
		// 				body={<HTML>
		// 					<p>{intl.formatMessage({ id: "trip2.desc_p1" })}</p>
		// 					<span className="price">{intl.formatMessage({ id: "trip2.price" })}</span>
		// 					<p className="center">
		// 						<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
		// 					</p>
							
		// 				</HTML>}
		// 			/>
		// 			</div>
		// 			</section>
		// 			<section className="wrapper container wrap narrow">
		// 			<div className="container trips big">
		// 			<Trip
		// 				className="overlap overlap-mini"
		// 				title={intl.formatMessage({ id: "trip3.name" })}
		// 				duration={intl.formatMessage({ id: "trip3.duration" })}
						
		// 				link="/page-2"
		// 				body={<HTML>
		// 					<p>{intl.formatMessage({ id: "trip3.desc_p1" })}</p>
		// 					<span className="price">{intl.formatMessage({ id: "trip3.price" })}</span>
		// 					<p className="center">
		// 						<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
		// 					</p>
							
		// 				</HTML>}
		// 			/>
		// 			<Trip
		// 				className="overlap overlap-mini"
		// 				title={intl.formatMessage({ id: "trip4.name" })}
		// 				duration={intl.formatMessage({ id: "trip4.duration" })}
						
		// 				link="/page-2"
		// 				body={<HTML>
		// 					<p>{intl.formatMessage({ id: "trip4.desc_p1-short" })}</p>
		// 					<span className="price">{intl.formatMessage({ id: "trip4.price" })}</span>
		// 					<p className="center">
		// 						<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
		// 					</p>
							
		// 				</HTML>}
		// 			/>
		// 		</div>
		// 	</section>
		// </Layout>
		<Layout pageName="gallery_page" siteTitle="gallery.page_title">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg overlap" style={{width:"100%"}}>
				{/* <p>{intl.formatMessage({ id: "gallery.desc_p1" })}</p> */}
				<div className="videoWrapper">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/NcCCHLKezVg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				{/* <div className="gallery gallery--2row">
							{gallery.edges.map((item, index) => { 
								const { node } = gallery.edges[index];
								return (
									<a href={node.big.fluid.src} target="_blank" className="gallery-item">
									<Img
										fluid={node.childImageSharp.fluid}
										key={node.id}
										alt={node.name.replace(/-/g, ' ').substring(2)}
									/>
									</a>
								)
							})}
							</div> */}
				</div>
			</section>
					
		</Layout>
	)
}

export default injectIntl(PricesPage)
